@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
  background:#f3f6f8;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none !important;
}

a i {
  color: black;
}
