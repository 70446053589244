.content {
    background:#fff;
    border-radius: 10px;
    margin-top:20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.smallGap{
    padding-right: 25px;
}