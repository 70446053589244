#lightbox {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    position: fixed;
    z-index: 1;
    visibility: hidden;
}

#lightbox img, #lightbox video{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 90vw; 
    max-height: 90vh;
}

.hide-lightbox{
    -webkit-animation: fadeout 0.3s;
            animation: fadeout 0.3s;
}

.show-lightbox{
    -webkit-animation: fadein 0.3s;
            animation: fadein 0.3s;
    visibility: visible !important;
}

@keyframes fadein {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}