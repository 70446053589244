.profile {
    margin-bottom: 80px;
}

.profile a {
    font-size: 12pt;
}

.profile-pic {
    margin-top: 20px;
    border-radius: 50%;
}

.profile-text {
    margin-top: 20px;
}
